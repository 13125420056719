@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.eyebrow {
  font-family: $sofiapro;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}

.innerWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: $white;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.title {
  font-family: $harrybeastregular;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;

  font-size: 24px;

  @include media-breakpoint-up(md) {
    font-size: 32px;
  }
}

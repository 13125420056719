@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.gradientContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.topGradient {
  height: 50%;
  background-image: linear-gradient(to bottom, $black, rgba($black, 0));
}

.bottomGradient {
  height: 50%;
  background-image: linear-gradient(0deg, $black, rgba($black, 0));
}

@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/themes.scss';
@import '../../../../styles/world/world-typography.scss';

.root {
  margin-top: -300px;

  &.reducedHeight {
    margin-top: -13.5%;

    @include media-breakpoint-up(md) {
      margin-top: -16%;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -18%;
    }

    .container {
      padding: 48px 20%;

      @include media-breakpoint-up(md) {
        padding: 48px 20% 58px;
      }
    }

    .inner {
      margin-bottom: 10px;
    }

    .eyebrow {
      padding-top: 0px;
    }

    .description {
      margin: 24px 0 32px;
      
      @include media-breakpoint-up(md) {
        margin: 20px 0 22px;
        width: 491px;
      }
    }
  }

  .container {
    background: green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 90px;
    margin-bottom: 50px;
    padding-left: 20%;
    padding-right: 20%;
    transform: skewY(9deg);

    .inner {
      -webkit-transform: skewY(-9deg);
      -moz-transform: skewY(-9deg);
      -ms-transform: skewY(-9deg);
      -o-transform: skewY(-9deg);
      transform: skewY(-9deg);
    }

    .eyebrow {
      font-family: $sofiapro;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      padding-top: 20px;

      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;
      letter-spacing: 1.33px;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: initial;
      }
    }

    .description {
      color: $gold-light;
      font-family: $sofiapro;
      font-size: 15px;
      font-weight: 400;
      margin-top: 21px;
      text-align: center;
      line-height: 18px;
      width: 297px;

      @include media-breakpoint-up(sm) {
        width: 320px;
      }

      @include media-breakpoint-up(md) {
        width: 556px;
        font-size: 20px;
        color: $white;
        line-height: 29px;
      }
    }

    .spaceshipIcon {
      width: 120px;
      height: 13px;
      position: relative;

      margin: 6px auto;

      @include media-breakpoint-up(md) {
        margin: 9px auto;
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }
}


[data-themed='true'] .container {
  background: var($--theme-spotlight-background);
}

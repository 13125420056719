.pages {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  li {
    list-style: none;
    display: inline-block;
    line-height: 42px;
  }
}

@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.root {
  width: 100%;

  .innerWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: $white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mainTitle {
    font-family: $harrybeastregular;
    color: $white;
    width: 80%;
    text-align: center;

    font-size: 34px;
    margin: 50px auto;
    line-height: 46px;

    &Dark {
      color: $grey-legal-darkest;
    }
    @include media-breakpoint-up(md) {
      font-family: $harrybeastdisplay;
      font-size: 40px;
      margin: 38px auto;
      line-height: normal;
    }
  }

  .secondaryBackground {
    display: grid;
    position: relative;
    width: 100%;

    grid-template-columns: 1fr;
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@import '../../styles/variables.scss';

.page {
  $page: &;
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: 0;
  color: $white-floral;
  font-family: $harrybeastregular;
  font-size: 13px;
  line-height: 13px;
	cursor: pointer;
  padding: 0 8px 0 0;
  &:after, &:before {
    content: '';
    width: 0;
    height: 1px;
    background-color: $white-floral;
    opacity: .3;
    vertical-align: middle;
    display: inline-block;
    transition: width .5s ease-in-out;
  }

  &.dark {
    color: $white-floral;
    &:after, &:before {
      background-color: $white-floral;
    }
  }

  &.light {
    color: $ww-black;
    &:after, &:before {
      background-color: $ww-black;
    }
  }

  :global {
    .prefix {
      display: inline-block;
      margin-right: 10px;
      font-style: italic;
    }
  }

  &.isGenericPage {
    color: var(--theme-carousel-color);
    &:after, &:before {
      background-color: var(--theme-carousel-color);
    }
  }
}

:global {
  .long-pagination {
    :local {
      .page {
        &:after {
          display: none;
        }
      }
    }
  }
  li:last-child {
    :local {
      .page {
        &:after {
          height: 0;
          width: 0;
          margin: 0;
        }
      }
    }
    &.slick-active {
      :local {
        .page:before {
          width: 32px;
          margin-right: 7px;
        }
      }
    }
  }
  .slick-active {
    ~ li {
      :local {
        .page {
          opacity: .3;
        }
      }
    }
    :local {
      .page {
        opacity: 1;
        &:after {
          width: 32px;
          margin-left: 7px;
        }
      }
    }
  }
}
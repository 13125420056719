@mixin container() {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 1024px;
  padding: 0 30px;

  @include media-breakpoint-up(sm) {
    padding: 0 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
    margin: 0 auto;
  }
}

@mixin container-lg() {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 1280px;
  padding: 0;

  @include media-breakpoint-up(sm) {
    padding: 0 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
    margin: 0 auto;
  }
}

@mixin section() {
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 0;
  box-sizing: border-box;

  @include media-breakpoint-up(md) { 
    padding: 0 32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 80px;
    max-width: 1440px;
  }
}

// @mixin section-full() {

// }

@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.root {
  position: relative;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: 0px;
  min-width: 339px;
  margin-top: 80px;
  overflow: hidden;
  padding-left: 30px;
  box-sizing: border-box;

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-up(sm) {
    margin-left: auto;
    min-width: unset;
  }

  @include media-breakpoint-up(xl) {
    max-width: calc(100% - ((100% - 1275px) / 2));
    margin-left: calc((100% - 1275px) / 2);
  }
}
.eyebrow {
  font-family: $harrybeastregular;
  font-weight: 400;
  color: var(--theme-text-color);

  font-size: 32px;
  line-height: 1.25;
  height: 80px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-direction: column;
  padding: 20px 0;

  &Dark {
    color: $grey-legal-darkest;
  }

  @include media-breakpoint-up(md) {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 1;
    height: 40px;
    width: 550px;
    margin: 0 0;
    padding-bottom: 0;
    text-align: left;
  }

  @include media-breakpoint-up(xl) {
    font-size: 40px;
    line-height: 1;
    height: 40px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 0;
    text-align: left;
  }
}

.flourish {
  width: 120px;
  height: 14px;
  margin: 10px auto;
}

.subhead1 {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 18px;
  text-align: center;
  @include sofiaRegular();
}

.subhead2 {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  @include sofiaRegular();
}

.sliderInner {
  position: relative;
  @include media-breakpoint-up(lg) {
    position: unset;
  }
}

.slider {
  margin-top: 43px;
}

.dots,
.lower.dotContainer {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;

  li {
    display: inline-block;
    list-style: none;

    button {
      font-size: 0;
      line-height: 0;
      padding: 0 6px 0 0;
      border: 0;
      background-color: transparent;

      &:before {
        content: '•';
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        height: 20px;
        text-align: center;
        opacity: 0.25;
        color: $ww-black;
      }
    }

    &:global(.slick-active) button:before {
      opacity: 0.75;
    }
  }
}

.lower.dotContainer {
  button {
    &:before {
      background-color: transparent;
      @include sofiaRegular();
      width: 6px;
    }
    &:after {
      content: none;
    }
    &:last-child:before {
      width: 6px;
    }
  }
  display: none;
}

.upperPagination {
  position: absolute;
  width: 100%;
  margin: auto;
  box-sizing: border-box;

  // default
  top: 335px;
  left: 0;
  text-align: left;

  @include media-breakpoint-up(sm) {
    // up SM
    top: 508px;
  }

  @include media-breakpoint-up(lg) {
    // up LG
    top: -8.5%;
    left: unset;
    text-align: right;
    max-width: 1245px;
    padding-right: 220px;
  }
}

.controls {
  position: absolute;
  left: 0;
  right: 102px;
  display: flex;
  align-content: center;
  width: 100%;
  max-width: 1255px;

  // default
  justify-content: flex-start;
  z-index: 1;
  top: 395px;

  @include media-breakpoint-up(sm) {
    // up SM
    justify-content: flex-start;
    z-index: 1;
    top: 570px;
  }

  @include media-breakpoint-up(md) {
    top: 555px;
  }

  @include media-breakpoint-up(lg) {
    // up LG
    justify-content: flex-end;
    z-index: 3;
    top: 30px;
  }
}

@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.button {
  display: inline;
  background: none;
  color: inherit;
  line-height: 42px;
  margin-right: 11px;
}

.light,
.dark {
  svg {
    pointer-events: visible;
    cursor: pointer;
    @media (pointer: fine) {
      :hover {
        fill: $white;
        stroke: $ww-black;
      }
    }
  }
}

.purple {
  svg {
    pointer-events: visible;
    cursor: pointer;
    &:hover {
      @media (pointer: fine) {
        fill: $ww-black;
        stroke: $white;
      }
    }
  }
}

.genericArrow > svg > g {
  stroke: var(--theme-carousel-color);
}
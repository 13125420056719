@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/layouts.scss';
@import '../../../../styles/typography.scss';

.promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  position: relative;
  margin-top: 100px;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;

  img {
    object-position: center;
  }
}

.contentWrapper {
  @include container;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 42vw;
  min-height: 550px;
  max-height: 600px;
  align-items: center;

  @include media-breakpoint-up(md) {
    max-width: 800px;
  }
}

.logo {
  width: 200px;
  @include media-breakpoint-up(md) {
    width: 350px;
  }
}

.displayTitle {
  @include sofiaSemiBold();
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 1.75px;
  margin-top: 17px;
  color: $white;

  @include media-breakpoint-up(md) {
    font-size: 72px;
    line-height: 70px;
    letter-spacing: 3.7px;
    margin-top: 0;
  }
}

.intro {
  font-family: $sofiapro;
  color: $white;
  font-size: 16px;
  line-height: 24px;
  padding: 0 12px;
  margin-top: 22px;
}

.buttonWrapper {
  margin-top: 22px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}

@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/world/world-custom.scss';
@import '../../../../styles/world/world-typography.scss';

.root {
  position: relative;
  margin: 30px 30px 0 0;

  @include media-breakpoint-up(md) {
    margin-top: 90px;
  }

  .articles {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    max-width: 1245px;
    margin: 0 auto 0;

    a {
      text-decoration: none;
    }
  }

  .article {
    text-align: center;
    flex: 0 0 100%;

    @include media-breakpoint-up(sm) {
      flex: 0 0 45%;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 30%;
    }
  }

  .image {
    width: 100%;
    padding-bottom: 64%;
    position: relative;
    margin-bottom: 10px;
  }

  .eyebrow {
    font-family: $harrybeastregular;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    color: $white;

    font-size: 32px;

    &Dark {
      color: $grey-legal-darkest;
    }
    @include media-breakpoint-up(md) {
      font-size: 38px;
    }
  }

  .wrapper {
    padding: 0 40px;
  }

  .tag {
    font-family: $sofiapro;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 2.11;
    color: $white;

    font-size: 16px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .header {
    @include sofiaSemiBold();
    line-height: 1.5;
    color: $white;
    margin: 0;

    font-size: 16px;

    &Dark {
      color: $grey-legal-darkest;
    }
    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  .by {
    font-family: $sofiapro;
    font-size: 14px;
    line-height: 1.5;
    color: $white;
    margin: 18px 0 40px;

    &Dark {
      color: $grey-legal-darkest;
    }
  }
}

@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables.scss';
@import '../../../../styles/themes.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-width: 310px;

  p {
    font-size: 16px;
    line-height: 24px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 20px;
    }

    @include media-breakpoint-up(xm) {
      font-size: 16px;
      line-height: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-top: 100px;
    max-height: 700px;
    max-width: 100%;
  }
}

.imgContainer {
  align-self: flex-start;
  position: relative;
  width: 90%;
  height: 90%;
  margin-top: 40px;
  max-width: 550px;
  max-height: 550px;

  &.animate {
    @include media-breakpoint-up(md) {
      transform: translate(0px, 10px);
      transition: transform 2s ease-in-out;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 700px;
    width: 55vw;
    height: 55vw;
    margin-top: -10px;
    margin-left: 1vw;
  }

  & > div {
    display: flex;
  }
}

.contentRight {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  position: relative;
  margin-top: 90%;
  margin-left: 10%;
  width: 90%;
  height: 90%;
  max-width: 550px;
  max-height: 600px;

  @include media-breakpoint-up(md) {
    max-width: 700px;
    width: 45vw;
    height: 55vw;
    margin-left: -50px;
  }

  @include media-breakpoint-up(xl) {
    width: 590px;
    height: 700px;
  }

  &.animate {
    @include media-breakpoint-up(md) {
      margin-top: 10px;
      max-height: 700px;
      transform: translate(0px, -10px);
      transition: transform 2s ease-in-out;
    }
  }

  .textContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin: 15% 0;
    width: 80%;

    @include media-breakpoint-up(md) {
      width: 80%;
    }

    @include media-breakpoint-up(lg) {
      width: 75%;
    }

    @include media-breakpoint-up(xl) {
      width: 390px;
    }
  }
}

p {
  &.eyebrow {
    width: 100%;
    font-family: $sofiapro;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 0px;

    font-size: 14px;
    color: #ededef;
    line-height: 2.71;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
      color: $white;
      line-height: 38px;
    }
  }
}

.title {
  width: 100%;
  font-family: $harrybeastregular;
  margin-top: 0px;
  margin-bottom: 26px;
  color: $white;

  font-size: 24px;
  line-height: 1.33;
  font-weight: normal;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 1.25;
  }

  @include media-breakpoint-up(xm) {
    font-size: 24px;
    line-height: 1.33;
  }

  @include media-breakpoint-up(lg) {
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
  }
}

.description {
  width: 100%;
  font-family: $sofiapro;
  margin-top: 0px;
  margin-bottom: 30px;
  color: $gold-light;

  font-size: 16px;
  line-height: 1.5;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.author {
  font-family: $sofiapro;
  font-weight: 600;
  color: $grey-legal-border;
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 1.71;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 1.5;
  }
}

.featureBtn {
  width: 100%;

  .btn {
    min-width: 151px;
  }
}

[data-themed='true'] .contentRight {
  background-color: var($--theme-spotlight-background);
}

@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/world/world-custom.scss';
@import '../../../../styles/world/world-typography.scss';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  max-width: 300px;
  min-width: 220px;
  margin-left: 39%;
  margin-right: 39%;
  picture {
    position: static;

    img {
      height: 100%;
    }
  }
}

@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

$gradient: linear-gradient(to bottom, rgba($ww-black, 0), rgba($black, 0.6) 80%);

.background {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  & picture:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: $gradient;
  }

  & > a {
    position: relative;
  }

  height: 252px;

  @include media-breakpoint-up(md) {
    height: 600px;
  }
}

.secondaryItem {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;

  & > a {
    position: relative;
  }

  & picture:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: $gradient;
  }

  height: 252px;

  @include media-breakpoint-up(md) {
    height: 484px;
  }
}

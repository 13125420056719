@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.root {
  display: block;
  background: transparent;

  //default
  margin-right: 10px;
  width: 315px;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    margin-right: 40px;
    width: 400px;
    overflow: hidden;
  }

  @include media-breakpoint-up(md) {
    margin-right: 30px;
    width: 500px;
  }

  img {
    width: 315px;
    @include media-breakpoint-up(sm) {
      width: 488px;
    }
  }
}

.title {
  font-family: $harrybeastregular;
  font-weight: bold;
  color: var(--theme-text-color);
  object-fit: contain;

  margin: 135px 0 0;
  font-size: 24px;
  line-height: 1.33;

  &Dark {
    color: $grey-legal-darkest;
  }
  @include media-breakpoint-up(lg) {
    margin: 43px 0 0;
    font-size: 32px;
    line-height: 1.19;
  }
}

.description {
  font-family: $sofiapro;
  color: var(--theme-text-color);
  margin-bottom: 29px;
  padding: 0;

  font-size: 16px;
  line-height: 1.5;
  margin-top: 19px;

  &Dark {
    color: $grey-legal-darkest;
  }
  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 58px;
  }
}

.readFeature {
  font-family: $sofiapro;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.09px;
  text-align: center;
  color: var(--theme-text-color);
  margin-right: 7px;

  &Dark {
    color: $grey-legal-darkest;
  }
}

@media (orientation: portrait) {
  :global(.slick-slide:not(.slick-active)) {
    .title,
    .description,
    .readFeature {
      padding-left: 60px;
      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }
  }
}

// overwrite arrow color to page theme for GenericPage
.arrow > svg > g > g > g {
  fill: var(--theme-text-color);
}